<script setup>
import { Head, Link, usePage } from "@inertiajs/vue3";
import ApplicationLogo from "@Components/ApplicationLogo.vue";
import CreativeExperimentImg from "@Images/creative-experiment.svg";
import { onMounted } from "vue";
import { isNotNullOrEmpty, notify } from "@Res/js/helpers";
import Icons from "@Components/Icons.vue";

defineProps({
    title: String,
});

onMounted(() => {
    if (
        typeof usePage().props.errors !== "undefined" &&
        Object.values(usePage().props.errors).length > 0
    ) {
        notify({
            text: Object.values(usePage().props.errors)[0],
            type: "error",
        });
    }

    if (isNotNullOrEmpty(usePage().props.status)) {
        notify({
            text: usePage().props.status,
            type: "success",
        });
    }
});
</script>

<template>
    <Head :title="title" />

    <section class="bg-white dark:bg-gray-900 min-h-screen lg:space-y-10">
        <header class="mx-auto py-5 px-4 sm:px-6 lg:px-8">
            <nav class="relative z-50 flex justify-between h-10">
                <div class="flex items-center md:gap-x-12">
                    <Link :href="route('home')" aria-label="Home">
                        <ApplicationLogo class="text-primary" />
                    </Link>
                </div>
                <div class="flex items-center gap-x-5 md:gap-x-8">
                    <template v-if="$slots.header_cta">
                        <slot name="header_cta" />
                    </template>
                </div>
            </nav>
        </header>

        <div class="flex justify-center">
            <div
                class="hidden items-center w-full max-w-3xl px-8 mx-auto lg:px-12 bg-cover lg:w-3/5 xl:flex xl:w-2/3"
            >
                <div class="w-full flex flex-col">
                    <h1
                        class="text-6xl font-bold text-gray-700 dark:text-white"
                    >
                        The platform to sell your digital products
                    </h1>

                    <p
                        class="mt-5 mb-16 text-2xl text-gray-500 dark:text-gray-400"
                    >
                        ...making digital products easy to sell
                    </p>

                    <img :src="CreativeExperimentImg" alt="Be Creative" />
                </div>
            </div>

            <div
                class="flex items-center w-full max-w-3xl p-8 xl:p-0 mx-auto lg:w-3/5 xl:w-1/3"
            >
                <div class="w-full bg-white rounded-lg">
                    <slot />
                </div>
            </div>
        </div>
    </section>

    <Icons />
</template>
